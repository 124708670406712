import { useState, useRef, useEffect } from 'react';
import { styled } from '../../stitches.config';
import MenuItem from './MenuItem/MenuItem';
import Overlay from '../../Atoms/Overlay/Overlay';
import { useHeaderData } from '../Header/Header';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import useMedia from '../../Shared/Hooks/useMedia';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import IHeaderLinkModel from '../../Shared/AutoMapInterfaces/IHeaderLinkModel.interface';

type PropTypes = {
  isOpen: boolean;
  onMenuClose: () => void;
};

function Menu({ isOpen, onMenuClose }: PropTypes) {
  const { links, profileLinks, commerceLinks } = useHeaderData();
  const { authenticated } = useUserStateData();

  const [activeId, setActiveId] = useState<string>('');
  const menuRef = useRef<HTMLDivElement>(null);

  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    let headerElm = document.getElementById('header');
    headerElm && setHeaderHeight(headerElm.offsetHeight);
  });

  useOutsideClick(menuRef, () => {
    isOpen && onMenuClose();
  });
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  return (
    <>
      <Wrapper
        ref={menuRef}
        css={{ transform: isOpen ? 'translateX(0)' : 'translateX(-100%)' }}
      >
        <List css={{ maxHeight: `calc(100% - ${headerHeight}px)` }}>
          {commerceLinks &&
            commerceLinks.map((item: IHeaderLinkModel) => (
              <MenuItem
                key={item.id}
                item={item}
                toggleMenu={onMenuClose}
                activeId={activeId}
                setActive={() => setActiveId(item.id)}
                expandedByDefault={true}
              />
            ))}

          {commerceLinks && commerceLinks.length > 0 && <StyledDivider />}

          {links &&
            links.map((item: IHeaderLinkModel) => (
              <MenuItem
                key={item.id}
                item={item}
                toggleMenu={onMenuClose}
                activeId={activeId}
                setActive={(id) => setActiveId(id)}
                type={'pages'}
              />
            ))}

          {authenticated &&
            isMobile &&
            profileLinks &&
            profileLinks.length > 0 && <StyledDivider />}

          {authenticated &&
            isMobile &&
            profileLinks &&
            profileLinks.length > 0 &&
            profileLinks.map((item: IHeaderLinkModel) => (
              <MenuItem
                key={item.id}
                item={item}
                toggleMenu={onMenuClose}
                activeId={activeId}
                setActive={(id) => setActiveId(id)}
                isProfileLinks
              />
            ))}
        </List>
      </Wrapper>
      {isOpen && <Overlay />}
    </>
  );
}

export default Menu;

const StyledDivider = styled('div', {
  marginTop: '110px',
  '@mediaMaxLarge': {
    marginTop: '66px',
  },
});

const Wrapper = styled('div', {
  position: 'fixed',
  l: 0,
  backgroundColor: '$white',
  h: '100%',
  w: '100%',
  maxW: '420px',
  transition: '$300 all',
  zIndex: '$Flyout',
  p: 0,
  '@mediaMaxLarge': {
    t: '32px',
  },
});

const List = styled('ul', {
  listStyle: 'none',
  m: 0,
  overflowY: 'scroll',
  py: '62px',
  px: 0,
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  scrollbarWidth: 'none',
  '@mediaMaxLarge': {
    maxHeight: 'calc(100% - 100px)',
    paddingTop: '37.5px',
    paddingBottom: '62px',
  },
});
