import {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import { styled } from '../../../stitches.config';
import { ChevronIcon, CloseIcon } from '../../../Atoms/Icons';
import KexLink from '../../../Kex/KexLink';
import { useUserStateData } from '../../../Shared/UserContextProvider/UserContextProvider';
import { SignOut } from '../../../Pages/LoginPage/Account';
import { useAppSettingsData } from '../../../Shared/Providers/AppSettingsProvider';
import useMedia from '../../../Shared/Hooks/useMedia';
import useCurrentPage from '../../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../../Shared/Models/PageModelBase.interface';
import { mediaQueryTypes } from '../../../Theme/Settings/mediaQueries';
import IHeaderLinkModel from '../../../Shared/AutoMapInterfaces/IHeaderLinkModel.interface';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';

type PropTypes = {
  item: IHeaderLinkModel;
  toggleMenu?: () => void;
  activeId?: string;
  setActive: (id: string) => void;
  depth?: number;
  isProfileLinks?: boolean;
  expandedByDefault?: boolean;
  type?: string;
  isSubLink?: boolean;
  isSubLinkPages?: boolean;
};

function MenuItem({
  item,
  toggleMenu,
  activeId,
  setActive,
  depth = 0,
  isProfileLinks,
  expandedByDefault = false,
  isSubLink,
  isSubLinkPages,
  type,
}: PropTypes) {
  const { authenticated } = useUserStateData();
  const { languageRoute } = useAppSettingsData();
  const { 'account/signOut': signOut } = useTranslationData();
  const { pageType, pageId, channelId } = useCurrentPage<PageModelBase>();

  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);
  const [isSubMenuOpen, setSubMenuOpen] = useState<boolean>(expandedByDefault);
  const [subLinkContainerHeight, setSubLinkContainerHeight] = useState<
    0 | 'auto'
  >(0);
  const subLinkContainerRef = useRef<HTMLDivElement>(null);
  const hasSubLinks = item.subLinks && !!item.subLinks.length;

  const findActiveNode = useCallback(
    (subs: IHeaderLinkModel[]) => {
      hasSubLinks &&
        subs.map((item: IHeaderLinkModel) =>
          pageId === item.id?.toString()
            ? setSubMenuOpen(true)
            : item.subLinks.length > 0 && findActiveNode(item.subLinks)
        );
    },
    [hasSubLinks, pageId]
  );

  useEffect(() => {
    if (pageType === 'StartPage') {
      !expandedByDefault && setSubMenuOpen(false);
      setActive('-1');
    }

    if (!expandedByDefault) {
      pageId === item.id?.toString()
        ? setActive(item.id)
        : hasSubLinks && findActiveNode(item.subLinks);
    }
  }, [
    pageId,
    item,
    expandedByDefault,
    findActiveNode,
    hasSubLinks,
    pageType,
    setActive,
  ]);

  useLayoutEffect(() => {
    const subLinkContainerElm = subLinkContainerRef.current;

    if (isSubMenuOpen) {
      if (subLinkContainerElm) {
        const onTransitionend = () => {
          subLinkContainerElm.style.cssText = ``;
          subLinkContainerElm.removeEventListener(
            'transitionend',
            onTransitionend
          );
          setSubLinkContainerHeight('auto');
        };
        subLinkContainerElm.style.cssText =
          'opacity: 0; position:absolute; height: auto;';
        const containerHeight = subLinkContainerElm.offsetHeight;

        subLinkContainerElm.style.cssText = '';

        subLinkContainerElm.addEventListener('transitionend', onTransitionend);
        setTimeout(() => {
          if (subLinkContainerElm) {
            subLinkContainerElm.style.cssText = `height: ${containerHeight}px;`;
          }
        }, 0);
      }
    } else {
      if (subLinkContainerElm) {
        const onTransitionend = () => {
          subLinkContainerElm.style.cssText = ``;
          subLinkContainerElm.removeEventListener(
            'transitionend',
            onTransitionend
          );
          setSubLinkContainerHeight(0);
        };
        subLinkContainerElm.addEventListener('transitionend', onTransitionend);
        const containerHeight = subLinkContainerElm.offsetHeight;
        subLinkContainerElm.style.cssText = `height: ${containerHeight}px;`;
        setTimeout(() => {
          if (subLinkContainerElm) {
            subLinkContainerElm.style.cssText = `height: 0;`;
          }
        }, 0);
      }
    }
  }, [subLinkContainerHeight, isSubMenuOpen]);

  return (
    <Item>
      <NavLinkContainer>
        <NavLink
          href={item.href}
          onNavigated={() => {
            setActive(item.id);
            toggleMenu && toggleMenu();
          }}
          subLinks={hasSubLinks}
          depth={depth === 0}
          activeId={activeId === item.id}
          isSubMenuOpen={isSubMenuOpen}
          navType={type}
          isSubLink={isSubLink}
          isSubLinkPages={isSubLinkPages}
        >
          {item.text}
        </NavLink>
        {((!expandedByDefault && hasSubLinks) ||
          (!expandedByDefault && authenticated && isMobile && hasSubLinks)) && (
          <SubMenuButton onClick={() => setSubMenuOpen(!isSubMenuOpen)}>
            {/* Ugly but needed because of useOutsideClick behavior which would otherwise click through */}
            <IconWrapper isSubMenuOpen={!isSubMenuOpen}>
              <CloseIcon size={isMobile ? 'xs' : 'm'} />
            </IconWrapper>
            <IconWrapper isSubMenuOpen={isSubMenuOpen}>
              <ChevronIcon size={isMobile ? 'xs' : 'm'} />
            </IconWrapper>
          </SubMenuButton>
        )}
      </NavLinkContainer>
      {hasSubLinks && (
        <SubLinkContainer
          ref={subLinkContainerRef}
          firstDepth={depth === 1}
          depth={depth > 0}
          height={subLinkContainerHeight}
        >
          {item.subLinks.map((item: IHeaderLinkModel) => (
            <MenuItem
              key={item.linkId}
              item={item}
              toggleMenu={toggleMenu}
              activeId={activeId}
              setActive={setActive}
              depth={depth + 1}
              type={type}
              isSubLink={true}
              isSubLinkPages={type && true}
            />
          ))}
        </SubLinkContainer>
      )}
      {authenticated && isMobile && isProfileLinks && (
        <SubLinkContainer
          ref={subLinkContainerRef}
          css={{ height: subLinkContainerHeight }}
        >
          <StyledKexLink onClick={() => SignOut(languageRoute, channelId)}>
            {signOut}
          </StyledKexLink>
        </SubLinkContainer>
      )}
    </Item>
  );
}

const StyledKexLink = styled(KexLink, {
  display: 'flex',
  w: '100%',
  px: 4,
  py: 5,
  fs: 7,
});

const IconWrapper = styled('div', {
  variants: {
    isSubMenuOpen: {
      true: {
        display: 'none',
      },
      false: {
        display: 'block',
      },
    },
  },
});

const NavLinkContainer = styled('div', {
  display: 'flex',
});

const SubLinkContainer = styled('div', {
  h: 0,
  display: 'block',
  overflowY: 'hidden',
  transition: '$300 height cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  variants: {
    height: {
      auto: {
        h: 'auto',
      },
      0: {
        h: 0,
      },
    },
    firstDepth: {
      true: {
        borderLeftStyle: 'solid',
        blw: 0.25,
        borderLeftColor: '$primary',
      },
    },
    depth: {
      true: {
        ml: 4,
      },
    },
  },
});

const Item = styled('div', {
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

const NavLink = styled(KexLink, {
  position: 'relative',
  flexBasis: '100%',
  fontWeight: '$fw400',
  fontSize: '22px',
  lineHeight: '28px',
  letterSpacing: '1px',
  px: '16px',
  py: '17px',
  '@mediaMaxLarge': {
    fontSize: '14px',
    lineHeight: '23px',
  },
  variants: {
    subLinks: {
      true: {
        flexBasis: '85%',
      },
    },
    navType: {
      pages: {
        fontSize: '16px',
        lineHeight: '24px',
        py: '24px',
        '@mediaMaxLarge': {
          fontSize: '12px',
          lineHeight: '21px',
        },
      },
    },
    isSubLink: {
      true: {
        fontSize: '16px',
        lineHeight: '24px',
        py: '16px',
        '@mediaMaxLarge': {
          fontSize: '12px',
          lineHeight: '21px',
        },
      },
    },
    isSubLinkPages: {
      true: {
        fontSize: '12px',
        lineHeight: '20px',
        py: '10px',
        '@mediaMaxLarge': {
          fontSize: '10px',
          lineHeight: '19px',
        },
      },
    },
    depth: {
      true: {
        fontWeight: '$fw400',
        fontSize: '22px',
        '@mediaMaxLarge': {
          fontSize: '14px',
          lineHeight: '23px',
        },
      },
    },
    activeId: {
      true: {
        fontWeight: '$fw700',
      },
    },
    isSubMenuOpen: {
      true: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
});

const SubMenuButton = styled('button', {
  backgroundColor: '$white',
  bw: 0,
  py: 3,
  px: 6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':active': {
    outlineWidth: 0,
  },
  ':focus': {
    outlineWidth: 0,
  },
});

export default MenuItem;
